import React from 'react';
import Home from './pages/Home/Home';

function App() {
  return (
    <div className='App'>
      <section>
        <Home />
      </section>
    </div>
  );
}

export default App;
